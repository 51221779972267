<!--
 * @Author: Hou Jiechao
 * @Date: 2022-02-09 15:18:53
 * @LastEditors: Hou Jiechao
 * @LastEditTime: 2022-02-14 15:51:44
 * @FilePath: \工作\storehouse\src\pages\hotel\picking\index.vue
-->
<template>
  <Picking />
</template>

<script>
import Picking from "../../../components/picking.vue";

export default {
  name: "bandb_pick",
  components: {
    Picking,
  },
};
</script>